:root {
  --Content-max-width: 1280px;

  --Content-margin-top: 32px;

  @media screen and (min-width: 640px) {
    --Content-margin-top: 48px;
  }

  --Layout--padding-horizontal: 24px;

  @media screen and (min-width: 768px) {
    --Layout--padding-horizontal: 48px;
  }
}
